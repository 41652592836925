import React, { FC, ReactNode } from 'react';
import { getExternalFormsMapping } from '@/helpers/externalForm';
import { MasterLayoutProps } from '@/common/layouts/MasterLayout/types';
import { ResponsiveImageUrls } from '@/types';
import { ModalName } from '@/common/contexts/globalModalContext';
import MasterLayout from '@/common/layouts/MasterLayout/MasterLayout';
import SignUpSection, { SignUpSectionProps } from '@/common/components/SignUpSection/SignUpSection';
import { BannerProps } from '@/common/components/Banner/Banner';
import CustomReportModal, { CustomReportModalProps } from '@/common/components/CustomReportModal/CustomReportModal';
import PageHeader, { PageHeaderProps } from '@/pages/HomePage/components/PageHeader/PageHeader';
import Rethink, { RethinkItem } from '@/pages/HomePage/components/Rethink/Rethink';
import ArticlesCarousel from '@/pages/HomePage/components/ArticlesCarousel/ArticlesCarousel';
import NewsBlock, { NewsBlockProps } from '@/pages/HomePage/components/News/News';
import WatchSpace, { WatchSpaceProps } from '@/pages/HomePage/components/WatchSpace/WatchSpace';
import ExternalModalForms from '@/common/components/Modal/ExternalModalForms/ExternalModalForms';
import grid from '@/common/css/grid.module.scss';
import styles from './HomePage.module.scss';

interface HomePageProps extends MasterLayoutProps, PageHeaderProps, NewsBlockProps {
  rethink?: RethinkItem[];
  featured?: {
    title: string;
    mark: string;
    reports: Report[];
  };
  industryInsight?: {
    title: string;
    articles: IndustryInsight[];
  };
  researchArticles?: {
    title: string;
    articles: Article[];
  };
  watchSpace?: WatchSpaceProps;
  signUpSection?: SignUpSectionProps;
  banner?: BannerProps;
  customInsightPopup?: CustomReportModalProps;
}

interface Article {
  imageUrls: ResponsiveImageUrls;
  mainText: string;
  overlayText: string;
  button: {
    href: string;
  };
}

type IndustryInsight = Article;

interface Report {
  imageUrls: ResponsiveImageUrls;
  link: string;
  itemId: number;
  title: string;
  description: string;
}

const HomePage: FC<HomePageProps> = ({
  carousel,
  rethink,
  featured,
  industryInsight,
  researchArticles,
  news,
  watchSpace,
  signUpSection,
  banner,
  customInsightPopup,
  pardot,
  ...masterLayoutProps
}) => {
  const externalForms = getExternalFormsMapping(pardot);

  const renderReports = (): ReactNode => {
    if (!featured) {
      return null;
    }

    return (
      <ArticlesCarousel
        articles={featured.reports}
        title={featured.title}
      />
    );
  };

  const renderArticles = (title: string, articles: Article[], bannerProps?: BannerProps): ReactNode => (
    <ArticlesCarousel
      articles={articles.map((article: Article) => ({
        title: article.mainText,
        imageUrls: article.imageUrls,
        description: article.overlayText,
        link: article.button?.href,
      }))}
      banner={bannerProps}
      title={title}
    />
  );

  const renderIndustryInsights = (): ReactNode => {
    if (!industryInsight) {
      return null;
    }

    const { title, articles } = industryInsight;

    return renderArticles(title, articles, banner);
  };

  const renderResearchArticles = (): ReactNode => {
    if (!researchArticles) {
      return null;
    }

    const { title, articles } = researchArticles;

    return renderArticles(title, articles);
  };

  return (
    <MasterLayout {...masterLayoutProps}>
      <PageHeader carousel={carousel} />
      <div className={styles.contentContainer}>
        <div className={grid.container}>
          {rethink && (
            <Rethink items={rethink} />
          )}
          {renderReports()}
          {renderIndustryInsights()}
          {renderResearchArticles()}
          <NewsBlock news={news} />
        </div>
        {watchSpace && (
          <WatchSpace {...watchSpace} />
        )}
        {!!pardot && (
          <ExternalModalForms
            pardot={pardot}
            titles={{
              [ModalName.customReport]: customInsightPopup?.modalWindowTitle,
              [ModalName.newsletter]: signUpSection?.modalWindowTitle,
            }}
          />
        )}
        {signUpSection && (
          <SignUpSection {...signUpSection} shouldRenderModal={!externalForms[ModalName.newsletter]} />
        )}
        {(!externalForms[ModalName.customReport] && !!customInsightPopup) && (
          <CustomReportModal {...customInsightPopup} />
        )}
      </div>
    </MasterLayout>
  );
};

export default HomePage;
