import React, { FC } from 'react';
import key from 'weak-key';
import styles from './News.module.scss';

export interface NewsBlockProps {
  news?: NewsItem[];
}

interface NewsItem {
  dayOfWeek: string;
  date: string;
  title: string;
  url: string;
}

const NewsBlock: FC<NewsBlockProps> = ({
  news,
}: NewsBlockProps) => {
  if (!news) {
    return null;
  }

  return (
    <div className={styles.container}>
      {news.map(({
        url, title, dayOfWeek, date,
      }) => (
        <div key={key({ url })} className={styles.newsItem}>
          <a className={styles.title} href={url}>{title}</a>
          <div className={styles.dateBlock}>
            <div>{dayOfWeek}</div>
            <div>{date}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

NewsBlock.displayName = 'News';

export default NewsBlock;
