import React, {
  FC, memo, ReactElement, useCallback, useMemo,
} from 'react';
import classNames from 'classnames';
import { ResponsiveImageUrls } from '@/types';
import Carousel from '@/common/components/Carousel/Carousel';
import ResponsiveImage from '@/common/components/ResponsiveImage/ResponsiveImage';
import Button from '@/common/components/Button/Button';
import grid from '@/common/css/grid.module.scss';
import styles from './PageHeader.module.scss';

export interface PageHeaderProps {
  carousel?: CarouselItem[];
}

interface CarouselItem {
  imageUrls: ResponsiveImageUrls;
  title: string;
  description: string;
  button: {
    text: string;
    href: string;
  };
}

const PageHeader: FC<PageHeaderProps> = memo(({ carousel }: PageHeaderProps) => {
  const renderTitle = useCallback((title, index) => {
    if (!title) {
      return null;
    }

    const titleClassName = classNames(
      styles.title,
      grid.colSm6,
      grid.colXl8,
      grid.offsetSm1,
      grid.offsetMd2,
    );

    if (index) {
      return (
        <div className={titleClassName}>
          {title}
        </div>
      );
    }

    return (
      <h1 className={titleClassName}>
        {title}
      </h1>
    );
  }, []);

  const renderSlide = useCallback(({
    imageUrls,
    title,
    description,
    button,
  }: CarouselItem, index: number): ReactElement => (
    <>
      {!!imageUrls && (
        <ResponsiveImage alt={title} className={styles.image} urls={imageUrls} />
      )}
      <div className={styles.textContainer}>
        <div className={grid.row}>
          {renderTitle(title, index)}
        </div>
        <div className={grid.row}>
          <div className={classNames(
            styles.descriptionCol,
            grid.colSm9,
            grid.colMd7,
            grid.colLg6,
            grid.colXl8,
            grid.offsetSm1,
            grid.offsetMd2,
          )}
          >
            {description && (
              <h2 className={styles.description}>
                {description}
              </h2>
            )}
            {button && (
              <Button
                customClassNames={{ button: styles.button }}
                href={button.href}
                text={button.text}
              />
            )}
          </div>
        </div>
      </div>
    </>
  ), [renderTitle]);

  const slideProps = useMemo(() => ({
    itemRenderer: renderSlide,
    className: classNames(grid.container, styles.slide),
  }), [renderSlide]);

  const arrowsProps = useMemo(() => ({
    classNameLeft: classNames(styles.arrow, styles.arrowLeft),
    classNameRight: classNames(styles.arrow, styles.arrowRight),
  }), []);

  const dotsProps = useMemo(() => ({
    className: styles.dots,
  }), []);

  return (
    <div className={styles.container}>
      {!!carousel && (
        <Carousel arrowsProps={arrowsProps} dotsProps={dotsProps} items={carousel} slideProps={slideProps} />
      )}
    </div>
  );
});

export default PageHeader;
