import React, { FC, memo } from 'react';
import keys from 'weak-key';
import classNames from 'classnames';
import grid from '@/common/css/grid.module.scss';
import styles from './Rethink.module.scss';

interface RethinkProps {
  items: RethinkItem[];
}

export interface RethinkItem {
  text: string;
  href: string;
}

const Rethink: FC<RethinkProps> = memo(({
  items,
}: RethinkProps) => (
  <div className={grid.row}>
    <div className={classNames(styles.container,
      grid.colSm8, grid.colMd10, grid.colLg8,
      grid.offsetSm1, grid.offsetLg2)}
    >
      {items.map((item) => (
        <a key={keys(item)} href={item.href} className={styles.link}>
          {`${item.text} `}
        </a>
      ))}
    </div>
  </div>
));

export default Rethink;
