import React, {
  FC, ReactNode, ReactNodeArray, useCallback, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import key from 'weak-key';
import { debounce } from '@/helpers/debounce';
import Breakpoints from '@/common/constants/breakpoints';
import { ModalName, useToggleModalsContext } from '@/common/contexts/globalModalContext';
import ListCard, { ListCardProps } from '@/common/components/ListCard/ListCard';
import { useSwipeable } from 'react-swipeable';
import Banner, { BannerComponentProps } from '@/common/components/Banner/Banner';
import grid from '@/common/css/grid.module.scss';
import styles from './ArticlesCarousel.module.scss';

interface ArticlesCarouselProps {
  title: string;
  articles: ListCardProps[];
  banner?: BannerComponentProps;
}

const ArticlesCarousel: FC<ArticlesCarouselProps> = ({
  title,
  articles,
  banner,
}) => {
  const toggleModal = useToggleModalsContext();

  const showCustomReportModal = useCallback((): void => {
    toggleModal({ [ModalName.customReport]: true });
  }, [toggleModal]);

  const slidesAmount = banner ? articles.length + 1 : articles.length;

  const [currentArticleIndex, setCurrentArticleIndex] = useState(0);

  const renderCard = (article: ListCardProps): ReactNode => (
    <ListCard
      key={key(article)}
      {...article}
      customClasses={{
        container: styles.articleCard,
        imageContainer: styles.cardImageContainer,
        textContainer: styles.cardTextContainer,
      }}
      style={{ transform: `translateX(-${100 * currentArticleIndex}%)` }}
    />
  );

  const renderCards = (): ReactNode | ReactNodeArray => {
    if (banner) {
      const bannerProps = { ...banner, button: { ...banner.button, onClick: showCustomReportModal } };

      return (
        <>
          {renderCard(articles[0])}
          <div
            className={classNames(grid.colSm6, grid.colMd4, styles.banner)}
            style={{ transform: `translateX(-${100 * currentArticleIndex}%)` }}
          >
            <Banner {...bannerProps} />
          </div>
          {articles.slice(1).map(renderCard)}
        </>
      );
    }

    return articles.map(renderCard);
  };

  const renderBullets = (): ReactNode => {
    const bullets: ReactNode[] = [];

    for (let i = 0; i < slidesAmount; i += 1) {
      const bulletClasses = {
        [styles.bullet]: true,
        [styles.bulletActive]: i === currentArticleIndex,
      };

      const bulletClickHandler = (): void => {
        setCurrentArticleIndex(i);
      };

      bullets.push((<li key={key({ i })} className={classNames(bulletClasses)} onClick={bulletClickHandler} />));
    }

    return (<ul className={styles.bulletsContainer}>{bullets}</ul>);
  };

  const handleSwipeLeft = (): void => {
    if (currentArticleIndex === slidesAmount - 1) {
      return;
    }

    setCurrentArticleIndex(currentArticleIndex + 1);
  };

  const handleSwipeRight = (): void => setCurrentArticleIndex(currentArticleIndex - 1);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  });

  let latestViewportSize: number;

  const resizeHandler = debounce((): void => {
    const windowWidth = window.innerWidth;

    if ((latestViewportSize < Breakpoints.md) && (windowWidth >= Breakpoints.md)) {
      setCurrentArticleIndex(0);
    }

    latestViewportSize = windowWidth;
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return (): void => {
      window.removeEventListener('resize', resizeHandler);
    };
  });

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.wrapper}>
        <div
          className={classNames(grid.row, styles.slidesContainer)}
          {...swipeHandlers}
        >
          {renderCards()}
        </div>
        {renderBullets()}
      </div>
    </div>
  );
};

export default ArticlesCarousel;
