import React, { FC, memo } from 'react';
import classNames from 'classnames';
import { ResponsiveImageUrls } from '@/types';
import ResponsiveImage from '@/common/components/ResponsiveImage/ResponsiveImage';
import Button from '@/common/components/Button/Button';
import grid from '@/common/css/grid.module.scss';
import styles from './WatchSpace.module.scss';

export interface WatchSpaceProps {
  title: string;
  text: string;
  button: {
    text: string;
    href: string;
  };
  backgroundImages: ResponsiveImageUrls;
}

const WatchSpace: FC<WatchSpaceProps> = memo(({
  title, text, button, backgroundImages,
}: WatchSpaceProps) => (
  <div className={classNames(grid.container, styles.container)}>
    {!!backgroundImages && (
      <ResponsiveImage alt={title} className={styles.image} urls={backgroundImages} />
    )}
    <div className={classNames(styles.rowWrapper, grid.row)}>
      <div className={classNames(grid.colSm10, grid.colMd6, grid.colLg7, grid.offsetSm1)}>
        {title && (
          <div className={styles.title}>
            {title}
          </div>
        )}
        {text && (
          <div className={styles.description}>
            {text}
          </div>
        )}
        {button && (
          <Button
            customClassNames={{ button: styles.button }}
            href={button.href}
            text={button.text}
          />
        )}
      </div>
    </div>
  </div>
));

export default WatchSpace;
